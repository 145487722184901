import { Button, FormGroup, Input, Tooltip, UncontrolledTooltip } from "reactstrap";
import { deleteIcon } from "../../constant/icons";
import { useState } from "react";
import { confirmBox } from "../toast";

const RemoveControl = (props: any) => {
    const { column, rowData } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };
    return (
        rowData.jobStatus == "PENDING"?<>
        
        <Button color="link"
            disabled={rowData.jobStatus == "PENDING" ? false : true}
            onClick={async () => {
                const response = await confirmBox("Delete this Function");
                if (response && column.callBack) {
                    column.callBack({ ...rowData, clickType: "remove" })
                }
            }} className="text-danger" id="removeTooltip">{deleteIcon.icon}</Button>
        <UncontrolledTooltip isOpen={tooltipOpen} target="removeTooltip" toggle={toggleTooltip}>
            Remove
        </UncontrolledTooltip>
    </>:<></>
        
    )
};

export { RemoveControl };